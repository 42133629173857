import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { ENGLISH_TRANSLATIONS } from '../localization/en'
import { castToVueI18n, createI18n } from 'vue-i18n-bridge'

Vue.use(VueI18n, { bridge: true })
const TRANSLATIONS = {
    en: ENGLISH_TRANSLATIONS,
}

const i18n = castToVueI18n(
    createI18n(
        {
            locale: 'en',
            fallbackLocale: 'en',
            messages: TRANSLATIONS,
        },
        VueI18n
    )
)

Vue.use(i18n)

export { i18n }
