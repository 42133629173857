import { defineStore } from 'pinia'
import { useGlobalUiStore } from '@/store/globalUiStore'
import { logger } from '@/utils/logger'
import { accountPayoffQuote } from '@/services/api'
import { parseError } from '@/store/storeUtils'
import { AccountClosureReason, PayoffDetails } from '@/store/accountClosureStore.types'
import { openReplay } from '@/utils/openReplay'
import inspect from 'util-inspect'

export const useAccountClosureStore = () => {
    const accountClosureStore = defineStore('accountClosure', {
        state: () => {
            return {
                closeAccountReason: AccountClosureReason.NO_REASON_STATED,
                otherReason: null,
                isZeroBalancePayoff: false,
                payoffDetails: {
                    payoffDateInNycTime: new Date(),
                    totalCurrentBalance: 0,
                    interestAndFees: 0,
                    totalPayoffAmount: 0,
                    hasCountyRecordingFee: false,
                } as PayoffDetails,
            }
        },
        getters: {},
        actions: {
            async getPayoffQuote(payload: { requestDateInNycTime?: Date; payoffDateInNycTime?: Date }) {
                const globalUiStore = useGlobalUiStore()
                globalUiStore.error = ''
                logger.info(`Getting payoff details, requestDateInNycTime: ${payload.requestDateInNycTime} payoffDateInNycTime: ${payload.payoffDateInNycTime}`)
                try {
                    const { data } = await accountPayoffQuote(payload.requestDateInNycTime, payload.payoffDateInNycTime)
                    if (data.success) {
                        logger.info('payoff details: ' + inspect(data.payload))
                        this.payoffDetails = {
                            requestDateInNycTime: data.payload.requestDateInNycTime,
                            payoffDateInNycTime: data.payload.payoffDateInNycTime,
                            totalCurrentBalance: data.payload.currentBalance,
                            interestAndFees: data.payload.nonAmortizationAccruedInterests + data.payload.fixedInstallmentFees,
                            totalPayoffAmount: data.payload.totalAmountDue,
                            hasCountyRecordingFee: data.payload.hasCountyRecordingFee,
                        }
                        if (data.payload.totalAmountDue <= 0) {
                            this.isZeroBalancePayoff = true
                        }
                    } else {
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    parseError(e)
                    throw e
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(accountClosureStore)
    return accountClosureStore
}
