<template>
    <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        :class="show ? 'show' : ''"
        :style="{ display: show ? 'block' : 'none' }"
    >
        <div
            class="modal-dialog modal-dialog-centered"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <img
                        v-show="!disableClose"
                        class="close"
                        src="../assets/images/close.svg"
                        data-dismiss="modal"
                        @click="$emit('close')"
                        @keydown.enter="$emit('close')"
                        alt="Close button"
                    >
                    <h5
                        v-if="title"
                        class="title"
                    >
                        {{ title }}
                    </h5>
                </div>
                <div class="modal-body pt-1">
                    <slot />
                </div>
            </div>
        </div>
    </div>
    <!-- NOTE: modal-backdrop is in App.vue to maintain scrollability on desktop -->
</template>

<script setup lang="ts">
    import { onUnmounted, watch } from 'vue'

    const { show } =
        defineProps<{
            show: boolean
            title?: string
            disableClose?: boolean
        }>()

    watch(
        () => show,
        (value) => {
            if (value) {
                document.body.classList.add('modal-open')
                document.getElementById('modalBackdrop')?.classList?.add('show')
                document.getElementById('modalBackdrop')?.classList?.add('d-block')
            } else {
                document.body.classList.remove('modal-open')
                document.getElementById('modalBackdrop')?.classList?.remove('show')
                document.getElementById('modalBackdrop')?.classList?.remove('d-block')
            }
        }
    )

    onUnmounted(() => {
        // if a user times out, we want to remove these classes on destroyed
        document.body.classList.remove('modal-open')
        document.getElementById('modalBackdrop')?.classList?.remove('show')
        document.getElementById('modalBackdrop')?.classList?.remove('d-block')
    })
</script>

<style lang="scss" scoped>
    @import '../styles/components/modal';
</style>
