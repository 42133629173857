export const ENGLISH_TRANSLATIONS = {
    components: {
        avenFinancialCardOffer: {
            limitedTime: 'LIMITED-TIME',
            estimatedMonthlyPayments: 'ESTIMATED MONTHLY PAYMENTS',
            amountTransferred: 'AMOUNT TRANSFERRED',
            thirtyYearFixed: '30-YEAR FIXED',
            disclosure2: 'Estimated payments above assume a fixed APR of {apr} for 30-year plans.<sup>2</sup>',
            personalLoanRefi: {
                yourPersonalLoan: 'YOUR PERSONAL LOAN',
                buttonCta: `Lower Payment to {monthlyPayment}/mo<sup>1</sup>`,
            },
            helocRefi: {
                buttonCta: `Reduce My Monthly Payment`,
            },
            genericAvenCash: {
                buttonCta: `View My Offer`,
            },
        },
    },
}
