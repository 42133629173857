<template>
    <div>
        <aven-financial-card-offer
            :on-displayed-callback="onDisplayedCallback"
            :handle-button-click="handleButtonClick"
            :handle-display-disclosures="toggleModal"
            :aven-upgrade-data="overviewStore.avenUpgradeData"
            :title="title"
            :pill-text="i18n.t('components.uccToHomeUpgradeCard.pillText')"
            :description-html="descriptionHtml"
        />
        <modal-dialog
            :show="showDisclosuresModal"
            @close="toggleModal"
        >
            <p
                class="tw-text-xs tw-text-gray-400"
                v-html="modalContent"
            />
        </modal-dialog>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { useOverviewStore } from '@/store/overviewStore'
    import { UpgradeCardType } from 'aven_types'
    import { logEvent } from '@/services/http-client'
    import AvenFinancialCardOffer from 'aven_shared/src/components/AvenFinancialCardOffer.vue'
    import ModalDialog from 'aven_shared/src/components/ModalDialog.vue'
    import { toFormattedUSDStripTrailingZeroCents } from '@/mixins/format'
    import { i18n } from '@/utils/i18n'
    import { assertNever } from '@/utils/assertUtil'

    const overviewStore = useOverviewStore()
    const upgradeCardType = computed(() => overviewStore.avenUpgradeData.upgradeCardType)
    const avenMonthlyPayment = computed(() => overviewStore.avenUpgradeData.monthlyPaymentInfo?.avenMonthlyPayment)
    const otherLenderBalance = computed(() => overviewStore.avenUpgradeData.monthlyPaymentInfo?.otherLenderBalance)
    const showDisclosuresModal = ref(false)
    const modalContent = computed(() => overviewStore.avenUpgradeData.footnotes)

    const title = (() => {
        if (upgradeCardType.value === UpgradeCardType.none) {
            return ''
        } else if (upgradeCardType.value === UpgradeCardType.personalLoanRefi) {
            return i18n.t(`components.uccToHomeUpgradeCard.personalLoanRefi.title`)
        } else if (upgradeCardType.value === UpgradeCardType.helocRefi) {
            return i18n.t(`components.uccToHomeUpgradeCard.helocRefi.title`)
        } else if (upgradeCardType.value === UpgradeCardType.genericAvenCash) {
            return i18n.t(`components.uccToHomeUpgradeCard.genericAvenCash.title`)
        } else {
            throw assertNever(upgradeCardType.value, `Unknown upgradeCardType`)
        }
    })()

    const descriptionHtml = (() => {
        if (upgradeCardType.value === UpgradeCardType.none) {
            return ''
        } else if (upgradeCardType.value === UpgradeCardType.personalLoanRefi && avenMonthlyPayment.value) {
            return i18n.t(`components.uccToHomeUpgradeCard.personalLoanRefi.description`, { monthlyPayment: toFormattedUSDStripTrailingZeroCents(avenMonthlyPayment.value) })
        } else if (upgradeCardType.value === UpgradeCardType.helocRefi) {
            return i18n.t(`components.uccToHomeUpgradeCard.helocRefi.description`)
        } else if (upgradeCardType.value === UpgradeCardType.genericAvenCash) {
            return i18n.t(`components.uccToHomeUpgradeCard.genericAvenCash.description`)
        } else {
            throw assertNever(upgradeCardType.value, `Unknown upgradeCardType`)
        }
    })()

    const clickAnalyticsEventName = computed(() => {
        if (upgradeCardType.value === UpgradeCardType.personalLoanRefi) {
            return 'click_aven_my_ucc_to_home_pl_refi_upgrade_card'
        } else if (upgradeCardType.value === UpgradeCardType.helocRefi) {
            return 'click_aven_my_ucc_to_home_heloc_refi_upgrade_card'
        } else if (upgradeCardType.value === UpgradeCardType.genericAvenCash) {
            return 'click_aven_my_ucc_to_home_generic_aven_cash_upgrade_card'
        }
        return ''
    })

    const renderAnalyticsEventName = computed(() => {
        if (upgradeCardType.value === UpgradeCardType.personalLoanRefi) {
            return 'view_aven_my_ucc_to_home_pl_refi_upgrade_card'
        } else if (upgradeCardType.value === UpgradeCardType.helocRefi) {
            return 'view_aven_my_ucc_to_home_heloc_refi_upgrade_card'
        } else if (upgradeCardType.value === UpgradeCardType.genericAvenCash) {
            return 'view_aven_my_ucc_to_home_generic_aven_cash_upgrade_card'
        }
        return ''
    })

    const handleButtonClick = () => {
        logEvent(clickAnalyticsEventName.value)
        const statedUsage = otherLenderBalance.value ?? 25_000
        const link = `${process.env.VUE_APP_AVEN_URL}/origination/asset_to_home/${overviewStore.returnToken}/${btoa(
            overviewStore.avenUpgradeData.newUwPolicyName
        )}?cleanReload=true&utm_source=uccavenmy&utm_campaign=${upgradeCardType.value}&statedUsage=${statedUsage}`
        window.open(link, '_blank')
    }

    const toggleModal = () => {
        showDisclosuresModal.value = !showDisclosuresModal.value
    }

    const onDisplayedCallback = () => {
        logEvent(renderAnalyticsEventName.value)
    }
</script>

<style lang="scss" scoped></style>
