export const toFormattedPercentStripTrailingZerosAfterDecimalPoint = function (value: number): string {
    const stringNum = parseFloat(String(value * 100)).toFixed(2)
    if (stringNum.endsWith('.00')) {
        return stringNum.slice(0, stringNum.length - 3) + '%'
    }
    if (/\..0$/.test(stringNum)) {
        // match XX.X0 to drop the last 0
        return stringNum.slice(0, stringNum.length - 1) + '%'
    }
    return stringNum + '%'
}

export const toFormattedUSD = function (number: number): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)
}

export const toFormattedUSDStripTrailingZeroCents = (number: number): string => {
    let stringNum = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)

    stringNum = stringNum.replace(/\.00$/, '')

    return stringNum
}
